import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Select } from './rental-application';

const RentToOwn = () => (
  <Layout page="investor-information">
    <SEO title="Investor Information" />

    <div className="container quote">
      <div className="content">
        <h3>
          If you’ve ever wanted to invest in real estate but dont have time to
          renovate, manage tenants or find the right property, we offer a hands
          free investment opportunity.
        </h3>
      </div>
    </div>

    <div className="container">
      <div className="content slim">
        <h2>Why invest in real estate?</h2>

        <p>
          Real estate outpaces inflation, higher than traditional returns,
          secured against a tangible asset.
        </p>

        <hr />

        <h2>How is your investment protected?</h2>

        <p>
          First and second mortgage agreements, Joint Venture Agreements,
          Promissory Notes, Title Insurance.
        </p>

        <p>
          If you’re interested in learning about how we can work together,
          please fill out our investor questionaire below.
        </p>

        <form
          action="https://formspree.io/casey@yellowdogrealestate.ca"
          method="POST"
        >
          <h3>Personal Information</h3>

          <label className="required">
            Name <input type="text" name="name" required />
          </label>
          <label className="required">
            Phone number <input type="text" name="phone" required />
          </label>
          <label className="required">
            Email <input type="email" name="_replyto" required />
          </label>

          <h3>Rental Information</h3>

          <label>
            Current Employment? <input type="text" name="current-rent-amount" />
          </label>

          <label>
            Do you own your home?
            <input type="text" name="current-rent-amount" />
          </label>

          <label>
            How much equity is in your home?
            <input type="text" name="equity-in-home" />
          </label>

          <h3>Investment Information</h3>

          <label>
            Do you own RRSP’s? <Select name="own-rrsps" />
          </label>

          <label>
            What is their current value?
            <input type="text" name="rrsp-value" />
          </label>

          <label>
            What rate of return are they getting?
            <input type="text" name="rrsp-return" />
          </label>

          <label>
            Are you happy with the return?
            <Select name="happy-with-rrsps" />
          </label>

          <label>
            What are your financial goals for the next 5 years?
            <input type="text" name="5-year-goal" />
          </label>

          <label>
            What first hand real estate experience do you have?
            <input type="text" name="how-much-real-estate-experience" />
          </label>

          <label>
            How involved do you want to be with the investment?
            <input type="text" name="how-involved" />
          </label>

          <label>
            What returns are you seeking?
            <input type="text" name="what-returns-seeking" />
          </label>

          <label>
            What expectations do you have for monthly cashflow?
            <input type="text" name="monthly-cashflow-expectation" />
          </label>

          <label>
            How much money can you invest in a single project?
            <input type="text" name="how-much-invest-single-project" />
          </label>

          <label>
            What is your total investment capability?
            <input type="text" name="total-investment-capability" />
          </label>

          <label>
            Are the funds you are using cash or borrowed?
            <select name="cash-or-borrowed">
              <option>Cash</option>
              <option>Borrowed</option>
            </select>
          </label>

          <label>
            How readily available are your investment resources?
            <input type="text" name="how-readily-available" />
          </label>

          <label>
            Is there someone in your life that will be participating in your
            decision making?{' '}
            <input type="text" name="who-else-makes-decisions" />
          </label>

          <label>
            What is your personal risk tolerance?
            <select name="risk-tolerance">
              <option>Low</option>
              <option>Medium</option>
              <option>High</option>
            </select>
          </label>

          <label>
            Do you own any other investments, if so what does your portfolio
            consist of? <textarea name="other-investments" />
          </label>

          <input type="text" name="_gotcha" className="heyo" />

          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  </Layout>
);

export default RentToOwn;
